import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SearchBar from '../components/SearchBar';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import {
    Grid,
    Loader,
    Card,
    Banner as PGBanner,
    Title,
    Image,
    Text,
} from '@paygreen/paygreen-ui';
import QuestionList from '../components/QuestionList';
import ClickableCard from '../components/ClickableCard';
import { parseGraphQL } from '../utils/parsers/questionsParser';
import MostViewed from '../components/MostViewed';
import { sortPrismicItemByPriority } from '../utils/arrayHelper';
const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const tagNameToProduction =
    process.env.NAME_TAG_TO_QUESTION_PRODUCTION || 'valid-to-prod';

const IndexPage = ({ data }) => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerms, setSearchTerms] = useState('');
    const [questions, setQuestions] = useState([]);

    let themes = data.prismic.allThemes.edges || null;
    themes = themes.filter(
        theme =>
            (activeEnv === 'production' &&
                theme.node._meta.tags.includes(tagNameToProduction)) ||
            activeEnv === 'recette' ||
            activeEnv === 'development',
    );
    themes = sortPrismicItemByPriority(themes);

    const categories = data.prismic.allCategorys.edges || null;
    const { title, image, description } = data.prismic.homepage;
    const mostViewed = [];

    data.prismic.homepage.mostViewed.forEach(({ question }) => {
        if (question === null) {
            console.info(`Erreur de lien sur la page ${title}`);
        }
        if (
            (question !== null &&
                question._meta &&
                question._meta.tags.includes(tagNameToProduction)) ||
            activeEnv === 'recette' ||
            activeEnv === 'development'
        ) {
            mostViewed.push(parseGraphQL(question));
        }
    });

    const {
        metaTitle,
        metaDescription,
        ogUrl,
        ogTitle,
        ogDescription,
        ogImage,
    } = data.prismic.homepage;

    return (
        <Layout>
            <SEO
                metaTitle={metaTitle}
                description={metaDescription}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogUrl={ogUrl}
            />

            <PGBanner gradient="brand" topStyle="none" isFirstContent={true}>
                <Grid>
                    <Card
                        hasBackground={false}
                        blockWidth={image ? 'lg' : 'xl'}
                    >
                        {title ? (
                            <Title
                                htmlTag="h1"
                                colorType="reverse"
                                textSize="xl"
                                marginTop="lg"
                            >
                                {title}
                            </Title>
                        ) : null}

                        {description ? (
                            <Text
                                htmlTag="p"
                                colorType="reverse"
                                textSize="md"
                                marginTop="xs"
                            >
                                {description}
                            </Text>
                        ) : null}
                        <SearchBar
                            onResponse={setQuestions}
                            onChange={setSearchTerms}
                            isSearching={setIsSearching}
                            hasShadow
                        />
                    </Card>

                    {image ? (
                        <Image className="hideOnTablet" blockWidth="xs">
                            <img src={image.url} alt={image.alt} />
                        </Image>
                    ) : null}
                </Grid>
            </PGBanner>

            {searchTerms.length > 0 ? (
                <>
                    {isSearching ? (
                        <Loader
                            loaderSize="lg"
                            paddingTop="md"
                            paddingBottom="md"
                        />
                    ) : (
                        <Grid justifyContent="center">
                            <Card hasBackground={false} blockWidth="xl">
                                <QuestionList questions={questions} />
                            </Card>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    <Grid
                        displayType="grid"
                        columnNumber={3}
                        justifyItems="stretch"
                    >
                        {themes.map(({ node }) => {
                            let link = {
                                name: null,
                                href: '/' + node._meta.uid + '/',
                            };

                            let categoriesList = [];
                            categories.forEach(category => {
                                if (
                                    category.node.parentTheme._meta.uid ===
                                    node._meta.uid
                                ) {
                                    categoriesList.push(category.node);
                                }
                            });

                            return (
                                <ClickableCard
                                    data-testid={'cc-theme'}
                                    key={node._meta.uid}
                                    name={node.name}
                                    description={node.description}
                                    icon={node.icon}
                                    colorTheme={
                                        node.colorTheme === 'default'
                                            ? 'primary'
                                            : node.colorTheme
                                    }
                                    categories={categoriesList}
                                    link={link}
                                />
                            );
                        })}
                    </Grid>

                    <MostViewed questions={mostViewed} />
                </>
            )}
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        prismic {
            allCategorys {
                edges {
                    node {
                        _meta {
                            uid
                        }
                        name
                        parentTheme {
                            ... on PRISMIC_Theme {
                                _meta {
                                    uid
                                }
                            }
                        }
                    }
                }
            }
            allThemes {
                edges {
                    node {
                        _meta {
                            uid
                            tags
                        }
                        name
                        icon
                        description
                        colorTheme
                        priority
                    }
                }
            }
            homepage(lang: "fr-fr", uid: "home") {
                title
                description
                colorTheme
                image
                searchText
                metaTitle
                metaDescription
                ogUrl
                ogTitle
                ogDescription
                ogImage
                mostViewed {
                    question {
                        ... on PRISMIC_Question {
                            title
                            description
                            _meta {
                                uid
                                tags
                            }
                            parentCategory {
                                ... on PRISMIC_Category {
                                    _meta {
                                        uid
                                    }
                                    parentTheme {
                                        ... on PRISMIC_Theme {
                                            _meta {
                                                uid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
